@import url(https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
:root {
    --off-white: rgb(245, 245, 245);
    --light-grey: rgb(238, 238, 238);
    --button-fill: rgb(228, 228, 228);
    --nav-tile-grey: rgb(217, 217, 217);
    --border-grey: rgb(204, 204, 204);
    --grey: rgb(200, 200, 200);
    --inactive-icon-grey: rgb(185, 185, 185);
    --selected-grey: rgb(136, 136, 136);
    --active-icon-grey: rgb(109, 109, 109);
    --dark-grey: rgb(80, 80, 80);
    --sidebar-hover: rgb(53, 53, 58);
    --black: rgb(20, 20, 25);
    --highlight-blue: rgb(212, 236, 253);
    --light-blue: rgba(30, 167, 253, 0.8);
    --blue: rgb(73, 148, 202);
    --dark-blue: rgb(30, 75, 134);
    --calendar-selected-range: rgb(70, 90, 115);
    --calendar-selected-date: rgb(45, 65, 97);
    --green: rgb(106, 214, 117);
    --light-green: rgb(212, 238, 212);
    --red: #ca4949;
    --dark-red: #900000;
    --table-font-color: rgb(0, 0, 0);
    --table-status-red: rgb(214, 115, 106);
    --yellow: rgb(244, 128, 0);
    --light-yellow: rgb(255, 238, 170);
    --status-green: rgb(37, 123, 45);
    --status-blue: rgb(32, 120, 183);
    --overlay-background: #75757575;
    --dropdown-selected-blue: rgba(30, 75, 134, 0.8);
    --dropdown-focused-blue: rgba(178, 212, 255, 0.8);
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: unset;
}

input {
    border: 1px solid var(--grey);
}

input:focus {
    outline: 1px solid var(--blue);
    box-shadow: 0px 0px 6px rgba(64, 191, 188, 0.75);
}

:root {
    --z-index-layer-0: -2;
    --z-index-layer-1: 1;
    --z-index-layer-2: 10;
    --z-index-layer-3: 20;
    --z-index-layer-4: 30;
    --z-index-layer-5: 40;
    --z-index-layer-6: 50;
    --z-index-layer-7: 60;
    --table-box-shadow: 2px 2px 2px 2px rgba(33, 33, 33, 0.14);
    --font-stack: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-stack-condensed: 'Fira Sans Condensed';
    --font-stack-extra-condensed: 'Fira Sans Extra Condensed';
}

body {
    box-sizing: border-box;
    background-color: var(--off-white);
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--font-stack);
    --light: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--black);
}

h1,
h2,
h3,
h4 {
    margin: 1rem 0;
}

h1 {
    font-weight: normal;
    font-size: 2.5rem;
}

h2 {
    font-weight: normal;
    font-size: 2rem;
}

h3 {
    font-weight: normal;
    font-size: 1.75rem;
}

h4 {
    font-weight: normal;
    font-size: 1.5rem;
}

p {
    max-width: 40rem;
    line-height: 1.5;
}

textarea {
    font-family: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--font-stack);
}

code {
    font-family: 'Fira Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Universal box sizing */
html {
    box-sizing: border-box;
}

h2 {
    font-weight: 300;
}

a {
    text-decoration: none;
    color: var(--blue);
    font-family: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--font-stack);
}

button {
    font-family: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: var(--font-stack);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

td {
    vertical-align: top;
}

.App_title__-GfWs {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-bottom: 4rem;
    box-shadow: 0px 2px 4px var(--grey);
    padding: 0.75rem 0;
}

.App_name__2FSkX {
    display: flex;
    align-items: center;
}

.App_name__2FSkX h1 {
    font-weight: bold;
    margin: 0;
}

.App_titleContent__BlX1C {
    display: grid;
    grid-template:
        'logo titleText'
        'logo subTitle'
        'logo version';
}

.App_logo__1ItuU img {
    grid-area: logo;
    height: 3.75rem;
}

.App_titleText__2MnvW {
    grid-area: titleText;
    margin-bottom: 0;
    font-weight: bold;
}

.App_subTitle__1-mhw {
    text-align: left;
    font-size: 1rem;
    color: var(--dark-grey);
    margin-bottom: 0.25rem;
    grid-area: subTitle;
}

.App_version__HeCG4 {
    grid-area: version;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    color: #b0a9a9;
}

.App_message__2vrzR {
    z-index: 20;
    width: 100vw;
    position: fixed;
    bottom: 0;
}

.App_overlay__1bKt8 {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    z-index: 100;
    background-color: #75757575;
}

.App_wizardQuote__1V483 {
    display: inline-flex;
    position: fixed;
    top: 30vh;
    justify-self: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    width: 40rem;
}

.App_header__3ZZ1n {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--inactive-icon-grey);
    text-align: center;
    font-weight: bold;
}

.App_quote__F0e-p {
    font-size: 1.2rem;
    padding: 0 2rem;
    text-align: center;
}

.App_quotee__3DKTS {
    margin-left: auto;
    margin-right: 3rem;
    color: var(--selected-grey);
}

.App_rippleWrapper__3h2hg {
    display: inherit;
    justify-content: center;
}

.App_ripple__-Xgn0 {
    display: inline-block;
    position: relative;
    width: 72px;
    height: 72px;
}

.App_ripple__-Xgn0 div {
    position: absolute;
    border: 4px solid rgb(0, 0, 0);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: App_ripple__-Xgn0 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: App_ripple__-Xgn0 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.App_ripple__-Xgn0 div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}

@-webkit-keyframes App_ripple__-Xgn0 {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@keyframes App_ripple__-Xgn0 {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.dropzone_outerWrapper__2z9D2 {
    z-index: 100;
    display: grid;
    align-items: center;
    justify-content: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    cursor: pointer;
}

.dropzone_fixed__25Lbk {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
}

/* Don't run onDragLeave event when mousing over */
.dropzone_innerWrapper__3xIiK {
    pointer-events: none;
}

.dropzone_innerWrapper__3xIiK svg {
    width: 15rem;
    height: 15rem;
    stroke: #595959;
}

.dropzone_dropzone__3nyuG {
    border-radius: 30px;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.dropzone_instructions__jkaIw {
    color: black;
}

.dropzone_fixed__25Lbk {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
}

.dropzone_fixed__25Lbk .dropzone_dropzone__3nyuG {
    width: 500px;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 15px dashed #595959;
}

.dropzone_fixed__25Lbk .dropzone_innerWrapper__3xIiK svg {
    width: 100%;
    height: 100%;
}

.fieldSearch_root__2M8pl {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 17rem;
    justify-content: flex-start;
}

.fieldSearch_root__2M8pl > * {
    margin-right: 0.75rem;
}

.fieldSearch_root__2M8pl > *:last-child {
    margin-right: 0;
}

.fieldSearch_refresh__37fff {
    cursor: pointer;
    width: 32px;
}
.fieldSearch_root__2M8pl > button {
    white-space: nowrap;
}

.fieldSearch_matched__3HBID {
    color: var(--status-green);
}

.fieldSearch_unmatched__18UHO {
    color: var(--red);
}

.fieldSearch_searchContainer__3HbdZ {
    padding: 0.4rem;
    position: absolute;
    bottom: 0;
}

.fieldSearch_searchWrapper__1p0P9 {
    box-shadow: 5px 4px 14px var(--grey);
    border: 1px solid var(--grey);
    z-index: 20;
    position: absolute;
    top: 0;
    background-color: white;
    max-height: 20rem;
    overflow-y: auto;
    width: 30rem;
}

.fieldSearch_matchDescription__1nKka {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 8rem;
}

.fieldSearch_matchDescription__1nKka > * {
    margin-bottom: 0.3rem;
}

.fieldSearch_matchDescription__1nKka > *:last-child {
    margin-bottom: 0;
}

.fieldSearch_success__wAa9k {
    font-weight: 600;
    color: var(--green);
}

.fieldSearch_info__Ly5We {
    color: var(--blue);
}

.fieldSearch_match__19Nlb {
    white-space: nowrap;
    width: 5rem;
    text-align: left;
}

.fieldSearch_matchAlert__30LuZ {
    color: var(--yellow);
}

.fieldSearch_noMatchAlert__1dBK7 {
    color: var(--red);
}

.itemSearch_form__2XqJr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1rem 1rem;
    z-index: 10;
    border-bottom: 1px solid var(--grey);
}

.itemSearch_form__2XqJr > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 8rem;
}

.itemSearch_form__2XqJr input {
    font-family: monospace;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.4rem;
}

.itemSearch_root__1HtmE tr {
    cursor: pointer;
}

.itemSearch_root__1HtmE td:first-child {
    font-family: monospace;
}

.editItem_root__3Mbel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.editItem_refresh__12Y7h {
    cursor: pointer;
    width: 32px;
    margin-right: 0.75rem;
}

.itemImage_root__1gEMb {
    max-height: 84px;
    max-width: 84px;
}

.itemCheck_root__2Ak41 {
    height: 100%;
}

.itemCheck_tabs__2yRzo {
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(33, 33, 33, 0.14);
    box-shadow: var(--table-box-shadow);
    margin-bottom: 1rem;
}

.itemCheck_tabs__2yRzo span > div {
    padding: 1.5rem;
}

.itemCheck_tabs__2yRzo span > div {
    padding: 1.5rem;
    border-bottom: 0.5rem solid white;
}

.itemCheck_tabs__2yRzo div:hover {
    padding: 1.5rem;
    border-bottom: 0.5rem solid #eeeeee;
}

.itemCheck_hidden__1nCM- {
    display: none;
}

.itemCheck_root__2Ak41 thead > tr {
    background-color: white;
}

.itemCheck_root__2Ak41 thead tr td {
    padding: 0;
}

.itemCheck_root__2Ak41 thead tr td div:first-child {
    height: 3rem;
    margin-bottom: 0;
}

div.itemCheck_root__2Ak41 tbody tr:nth-child(odd) {
    background-color: white;
}

div.itemCheck_root__2Ak41 tbody tr:hover {
    background-color: var(--highlight-blue);
}

.itemCheck_root__2Ak41 table {
    background-color: transparent;
    box-shadow: none;
}

div.itemCheck_root__2Ak41 tbody td {
    box-shadow: inset 0px -1px 0px var(--grey);
    padding: 1rem 0.5rem;
}

.itemCheck_root__2Ak41 td:first-child {
    border-radius: 5px 0 0 5px;
}

.itemCheck_root__2Ak41 td:last-child {
    border-radius: 0 5px 5px 0;
    box-shadow: inset -1px -1px 0px var(--grey);
}

.itemCheck_createOrderButton__38Joy {
    display: flex;
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid var(--grey);
    border-radius: 5px;
    z-index: 20;
}

.itemCheck_createOrderButton__38Joy button {
    margin-left: 0.5rem;
    border: none;
    padding: 1rem;
    color: green;
    background-color: var(--light-green);
    border-radius: 5px;
    box-shadow: 1px 1px 3px var(--grey);
    display: inline-flex;
    align-items: center;
}

.itemCheck_createOrderButton__38Joy .itemCheck_submitButton__gtOcQ:hover {
    color: var(--status-green);
    background-color: var(--green);
}

.itemCheck_inactive__lLtz2 button,
.itemCheck_inactive__lLtz2 button:hover {
    color: var(--grey);
    background-color: var(--light-grey);
}

.itemCheck_createOrderButton__38Joy div {
    padding-left: 0.25rem;
}

.itemCheck_createOrderButton__38Joy .itemCheck_cancelButton__B9zhB {
    background-color: var(--red);
    color: white;
}

.itemCheck_createOrderButton__38Joy .itemCheck_cancelButton__B9zhB:disabled {
    background-color: var(--grey);
    color: white;
}

.itemCheck_nextButton__p7X1y {
    display: inline;
}

.itemCheck_nextButton__p7X1y button {
    outline: none;
    border: none;
    padding: 1rem;
    margin-top: 0.5rem;
    border-radius: 2px;
    background-color: var(--button-fill);
    box-shadow: 1px 1px 2px var(--grey);
}

.itemCheck_nextButton__p7X1y button:hover {
    background-color: var(--light-grey);
}

div.itemCheck_root__2Ak41 tbody tr.itemCheck_selectedRow__2V6Qz {
    background-color: var(--dropdown-focused-blue);
    border: 1px solid var(--blue);
    border-radius: 5px;
}

.itemCheck_metaTile__1z_eC {
    top: 1rem;
    margin-left: -20rem;
    height: 0;
    position: -webkit-sticky;
    position: sticky;
}

.itemCheck_preview__3ds3_ {
    display: block;
}

.itemCheck_subLineItem__36cR2 {
    padding: 0;
}

.itemCheck_root__2Ak41 .itemCheck_subLineItem__36cR2 td {
    background-color: white;
    padding: 0.5rem;
    padding-left: 1.5rem;
}

.itemCheck_subLineItem__36cR2 > td:first-child {
    visibility: hidden;
    position: absolute;
    padding: 0;
}

div.itemCheck_root__2Ak41 tbody tr.itemCheck_subLineItem__36cR2 {
    background-color: transparent;
}

.itemCheck_subLineItem__36cR2::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: var(--grey);
    background-color: transparent;
    width: 1.25rem;
    height: 2rem;
    border-bottom-left-radius: 7px;
}

.itemCheck_subLineItem__36cR2 td:nth-child(2) {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.itemCheck_spec__1D0xx {
    text-align: left;
    margin: 0;
}

.itemCheck_specWrapper__1UsdB {
    width: 12rem;
}

.projectSummary_root__1EZ6S {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 2px 2px 3px var(--light-grey);
    width: 18rem;
    z-index: 1;
    overflow: hidden;
}

.projectSummary_header__2_yNG {
    display: flex;
    align-items: baseline;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 1rem;
}

.projectSummary_counter__1CCic {
    font-size: 1.75rem;
    padding-right: 0.5rem;
    font-weight: 500;
}

.projectSummary_counterLabel__2BvA_ {
    color: var(--dark-grey);
}

.projectSummary_displayRow__1fxh1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0.5rem;
}

.projectSummary_label__1xrWz {
    color: var(--dark-grey);
    padding-right: 1rem;
}

.projectSummary_value__SkJa0 {
    font-weight: 500;
}

.projectSummary_navigator__Zrv_v {
    display: flex;
    margin-left: auto;
}

.projectSummary_navigator__Zrv_v button {
    display: inherit;
    border: none;
    padding: 0.3rem;
    margin-left: 0.2rem;
    cursor: pointer;
    box-shadow: 1px 1px 1px var(--grey);
}

.projectSummary_navigator__Zrv_v button:hover {
    background-color: var(--light-grey);
}

.projectSummary_displayRowWrapper__1R5vD {
    padding: 1rem 0;
    border-bottom: 1px solid var(--grey);
    border-top: 1px solid var(--grey);
}

.projectSummary_meta__FH_iy {
    border-bottom: 1px solid var(--grey);
}

.projectSummary_orders__3sx9W {
    max-height: 25rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.home_root__vQMmr {
    height: 100%;
    width: 100%;
}

.home_content__yP9VY {
    width: 60%;
    margin: auto;
    margin-bottom: 20rem;
}

.home_form__wmbGr {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    align-items: center;
}

.projectListControls_root__V8xZl {
    display: inline-flex;
    flex-direction: row;
    width: 11rem;
    justify-content: space-between;
}

.projectListControls_test__3PW6h {
    background-color: blue;
}

@custom-media --phone-large (min-width: 400px);
@custom-media --tablet (min-width: 800px);
@custom-media --desktop (min-width: 1200px);

