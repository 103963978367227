:root {
    --off-white: rgb(245, 245, 245);
    --light-grey: rgb(238, 238, 238);
    --button-fill: rgb(228, 228, 228);
    --nav-tile-grey: rgb(217, 217, 217);
    --border-grey: rgb(204, 204, 204);
    --grey: rgb(200, 200, 200);
    --inactive-icon-grey: rgb(185, 185, 185);
    --selected-grey: rgb(136, 136, 136);
    --active-icon-grey: rgb(109, 109, 109);
    --dark-grey: rgb(80, 80, 80);
    --sidebar-hover: rgb(53, 53, 58);
    --black: rgb(20, 20, 25);
    --highlight-blue: rgb(212, 236, 253);
    --light-blue: rgba(30, 167, 253, 0.8);
    --blue: rgb(73, 148, 202);
    --dark-blue: rgb(30, 75, 134);
    --calendar-selected-range: rgb(70, 90, 115);
    --calendar-selected-date: rgb(45, 65, 97);
    --green: rgb(106, 214, 117);
    --light-green: rgb(212, 238, 212);
    --red: #ca4949;
    --dark-red: #900000;
    --table-font-color: rgb(0, 0, 0);
    --table-status-red: rgb(214, 115, 106);
    --yellow: rgb(244, 128, 0);
    --light-yellow: rgb(255, 238, 170);
    --status-green: rgb(37, 123, 45);
    --status-blue: rgb(32, 120, 183);
    --overlay-background: #75757575;
    --dropdown-selected-blue: rgba(30, 75, 134, 0.8);
    --dropdown-focused-blue: rgba(178, 212, 255, 0.8);
}
