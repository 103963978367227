.root {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 2px 2px 3px var(--light-grey);
    width: 18rem;
    z-index: 1;
    overflow: hidden;
}

.header {
    display: flex;
    align-items: baseline;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 1rem;
}

.counter {
    font-size: 1.75rem;
    padding-right: 0.5rem;
    font-weight: 500;
}

.counterLabel {
    color: var(--dark-grey);
}

.displayRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: 0.5rem;
}

.label {
    color: var(--dark-grey);
    padding-right: 1rem;
}

.value {
    font-weight: 500;
}

.navigator {
    display: flex;
    margin-left: auto;
}

.navigator button {
    display: inherit;
    border: none;
    padding: 0.3rem;
    margin-left: 0.2rem;
    cursor: pointer;
    box-shadow: 1px 1px 1px var(--grey);
}

.navigator button:hover {
    background-color: var(--light-grey);
}

.displayRowWrapper {
    padding: 1rem 0;
    border-bottom: 1px solid var(--grey);
    border-top: 1px solid var(--grey);
}

.meta {
    border-bottom: 1px solid var(--grey);
}

.orders {
    max-height: 25rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
