.root {
    display: inline-flex;
    flex-direction: row;
    width: 11rem;
    justify-content: space-between;
}

.test {
    background-color: blue;
}
