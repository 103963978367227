.root {
    height: 100%;
    width: 100%;
}

.content {
    width: 60%;
    margin: auto;
    margin-bottom: 20rem;
}

.form {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    align-items: center;
}
