.form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1rem 1rem;
    z-index: 10;
    border-bottom: 1px solid var(--grey);
}

.form > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 8rem;
}

.form input {
    font-family: monospace;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.4rem;
}

.root tr {
    cursor: pointer;
}

.root td:first-child {
    font-family: monospace;
}
