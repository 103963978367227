.root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.refresh {
    cursor: pointer;
    width: 32px;
    margin-right: 0.75rem;
}
