.root {
    height: 100%;
}

.tabs {
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(33, 33, 33, 0.14);
    box-shadow: var(--table-box-shadow);
    margin-bottom: 1rem;
}

.tabs span > div {
    padding: 1.5rem;
}

.tabs span > div {
    padding: 1.5rem;
    border-bottom: 0.5rem solid white;
}

.tabs div:hover {
    padding: 1.5rem;
    border-bottom: 0.5rem solid #eeeeee;
}

.hidden {
    display: none;
}

.root thead > tr {
    background-color: white;
}

.root thead tr td {
    padding: 0;
}

.root thead tr td div:first-child {
    height: 3rem;
    margin-bottom: 0;
}

div.root tbody tr:nth-child(odd) {
    background-color: white;
}

div.root tbody tr:hover {
    background-color: var(--highlight-blue);
}

.root table {
    background-color: transparent;
    box-shadow: none;
}

div.root tbody td {
    box-shadow: inset 0px -1px 0px var(--grey);
    padding: 1rem 0.5rem;
}

.root td:first-child {
    border-radius: 5px 0 0 5px;
}

.root td:last-child {
    border-radius: 0 5px 5px 0;
    box-shadow: inset -1px -1px 0px var(--grey);
}

.createOrderButton {
    display: flex;
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid var(--grey);
    border-radius: 5px;
    z-index: 20;
}

.createOrderButton button {
    margin-left: 0.5rem;
    border: none;
    padding: 1rem;
    color: green;
    background-color: var(--light-green);
    border-radius: 5px;
    box-shadow: 1px 1px 3px var(--grey);
    display: inline-flex;
    align-items: center;
}

.createOrderButton .submitButton:hover {
    color: var(--status-green);
    background-color: var(--green);
}

.inactive button,
.inactive button:hover {
    color: var(--grey);
    background-color: var(--light-grey);
}

.createOrderButton div {
    padding-left: 0.25rem;
}

.createOrderButton .cancelButton {
    background-color: var(--red);
    color: white;
}

.createOrderButton .cancelButton:disabled {
    background-color: var(--grey);
    color: white;
}

.nextButton {
    display: inline;
}

.nextButton button {
    outline: none;
    border: none;
    padding: 1rem;
    margin-top: 0.5rem;
    border-radius: 2px;
    background-color: var(--button-fill);
    box-shadow: 1px 1px 2px var(--grey);
}

.nextButton button:hover {
    background-color: var(--light-grey);
}

div.root tbody tr.selectedRow {
    background-color: var(--dropdown-focused-blue);
    border: 1px solid var(--blue);
    border-radius: 5px;
}

.metaTile {
    top: 1rem;
    margin-left: -20rem;
    height: 0;
    position: sticky;
}

.preview {
    display: block;
}

.subLineItem {
    padding: 0;
}

.root .subLineItem td {
    background-color: white;
    padding: 0.5rem;
    padding-left: 1.5rem;
}

.subLineItem > td:first-child {
    visibility: hidden;
    position: absolute;
    padding: 0;
}

div.root tbody tr.subLineItem {
    background-color: transparent;
}

.subLineItem::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: var(--grey);
    background-color: transparent;
    width: 1.25rem;
    height: 2rem;
    border-bottom-left-radius: 7px;
}

.subLineItem td:nth-child(2) {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.spec {
    text-align: left;
    margin: 0;
}

.specWrapper {
    width: 12rem;
}
