@import '~normalize.css';
@import './shared/colors.css';
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

button {
    cursor: pointer;
}

button:disabled {
    cursor: unset;
}

input {
    border: 1px solid var(--grey);
}

input:focus {
    outline: 1px solid var(--blue);
    box-shadow: 0px 0px 6px rgba(64, 191, 188, 0.75);
}

:root {
    --z-index-layer-0: -2;
    --z-index-layer-1: 1;
    --z-index-layer-2: 10;
    --z-index-layer-3: 20;
    --z-index-layer-4: 30;
    --z-index-layer-5: 40;
    --z-index-layer-6: 50;
    --z-index-layer-7: 60;
    --table-box-shadow: 2px 2px 2px 2px rgba(33, 33, 33, 0.14);
    --font-stack: 'Fira Sans', sans-serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-stack-condensed: 'Fira Sans Condensed';
    --font-stack-extra-condensed: 'Fira Sans Extra Condensed';
}

body {
    box-sizing: border-box;
    background-color: var(--off-white);
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: var(--font-stack);
    --light: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--black);
}

h1,
h2,
h3,
h4 {
    margin: 1rem 0;
}

h1 {
    font-weight: normal;
    font-size: 2.5rem;
}

h2 {
    font-weight: normal;
    font-size: 2rem;
}

h3 {
    font-weight: normal;
    font-size: 1.75rem;
}

h4 {
    font-weight: normal;
    font-size: 1.5rem;
}

p {
    max-width: 40rem;
    line-height: 1.5;
}

textarea {
    font-family: var(--font-stack);
}

code {
    font-family: 'Fira Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Universal box sizing */
html {
    box-sizing: border-box;
}

h2 {
    font-weight: 300;
}

a {
    text-decoration: none;
    color: var(--blue);
    font-family: var(--font-stack);
}

button {
    font-family: var(--font-stack);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

td {
    vertical-align: top;
}
