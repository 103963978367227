.title {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-bottom: 4rem;
    box-shadow: 0px 2px 4px var(--grey);
    padding: 0.75rem 0;
}

.name {
    display: flex;
    align-items: center;
}

.name h1 {
    font-weight: bold;
    margin: 0;
}

.titleContent {
    display: grid;
    grid-template:
        'logo titleText'
        'logo subTitle'
        'logo version';
}

.logo img {
    grid-area: logo;
    height: 3.75rem;
}

.titleText {
    grid-area: titleText;
    margin-bottom: 0;
    font-weight: bold;
}

.subTitle {
    text-align: left;
    font-size: 1rem;
    color: var(--dark-grey);
    margin-bottom: 0.25rem;
    grid-area: subTitle;
}

.version {
    grid-area: version;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    color: #b0a9a9;
}

.message {
    z-index: 20;
    width: 100vw;
    position: fixed;
    bottom: 0;
}

.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    z-index: 100;
    background-color: #75757575;
}

.wizardQuote {
    display: inline-flex;
    position: fixed;
    top: 30vh;
    justify-self: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    width: 40rem;
}

.header {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--inactive-icon-grey);
    text-align: center;
    font-weight: bold;
}

.quote {
    font-size: 1.2rem;
    padding: 0 2rem;
    text-align: center;
}

.quotee {
    margin-left: auto;
    margin-right: 3rem;
    color: var(--selected-grey);
}

.rippleWrapper {
    display: inherit;
    justify-content: center;
}

.ripple {
    display: inline-block;
    position: relative;
    width: 72px;
    height: 72px;
}

.ripple div {
    position: absolute;
    border: 4px solid rgb(0, 0, 0);
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
