.root {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 17rem;
    justify-content: flex-start;
}

.root > * {
    margin-right: 0.75rem;
}

.root > *:last-child {
    margin-right: 0;
}

.refresh {
    cursor: pointer;
    width: 32px;
}
.root > button {
    white-space: nowrap;
}

.matched {
    composes: root;
    color: var(--status-green);
}

.unmatched {
    composes: root;
    color: var(--red);
}

.searchContainer {
    padding: 0.4rem;
    position: absolute;
    bottom: 0;
}

.searchWrapper {
    box-shadow: 5px 4px 14px var(--grey);
    border: 1px solid var(--grey);
    z-index: 20;
    position: absolute;
    top: 0;
    background-color: white;
    max-height: 20rem;
    overflow-y: auto;
    width: 30rem;
}

.matchDescription {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 8rem;
}

.matchDescription > * {
    margin-bottom: 0.3rem;
}

.matchDescription > *:last-child {
    margin-bottom: 0;
}

.success {
    font-weight: 600;
    color: var(--green);
}

.info {
    color: var(--blue);
}

.match {
    white-space: nowrap;
    width: 5rem;
    text-align: left;
}

.matchAlert {
    color: var(--yellow);
}

.noMatchAlert {
    color: var(--red);
}
