.outerWrapper {
    z-index: 100;
    display: grid;
    align-items: center;
    justify-content: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    cursor: pointer;
}

.fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
}

/* Don't run onDragLeave event when mousing over */
.innerWrapper {
    pointer-events: none;
}

.innerWrapper svg {
    width: 15rem;
    height: 15rem;
    stroke: #595959;
}

.dropzone {
    border-radius: 30px;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.instructions {
    color: black;
}

.fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
}

.fixed .dropzone {
    width: 500px;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 15px dashed #595959;
}

.fixed .innerWrapper svg {
    width: 100%;
    height: 100%;
}
